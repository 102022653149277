import React, { ReactElement } from "react"
import DefaultLayout from "../layouts/default"
import data from "../data/privacy"
import Heading1 from "../components/heading1"

interface Props {}

function PrivacyPage({}: Props): ReactElement {
  return (
    <div>
      <DefaultLayout title="Kontakte">
        <div className="mx-auto -max-w-1366 py-8 px-8 md:px-0">
          <div className="">
            <div className="py-8">
              <Heading1 text={data.heading} />
            </div>
            <div className="">
              {data.list.map((li: any) => (
                <>
                  <a href={"#" + li.title}>
                    <h3 className="text-2xl">{li.title}</h3>
                  </a>
                </>
              ))}
            </div>
            <div className="my-8">
              {data.list.map((li: any) => (
                <>
                  <a id={li.title} href={"#" + li.title}>
                    <h3 className="text-xl mb-2">{li.title}</h3>
                  </a>
                  <div
                    className="mb-12"
                    dangerouslySetInnerHTML={{ __html: li.html }}
                  />
                </>
              ))}
            </div>
          </div>
        </div>
      </DefaultLayout>
    </div>
  )
}

export default PrivacyPage
